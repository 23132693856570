import config from 'config'
import { isServer } from '@vue-storefront/core/helpers'
import { mapActions, mapState } from 'vuex';
import { addHandler } from 'theme/store/cart/helpers/tabEvent';
import { allSettled } from 'theme/helpers/allSetted';
import { updateSiteData } from 'theme/store/site-data';
import ChatWidget from 'theme/mixins/ChatWidget';
import { regionUrl } from '$modules/shipping/utils/region-url';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

export default {
  name: 'Prefetch',
  mixins: [ChatWidget],
  beforeMount () {
    EventBus.$on('region-changed', this.replaceByRegion)
    document.addEventListener('visibilitychange', this.checkOnActivate);
  },
  beforeDestroy () {
    EventBus.$off('region-changed', this.replaceByRegion)
    document.removeEventListener('visibilitychange', this.checkOnActivate);
  },
  mounted () {
    this.handleTabEvents()
  },
  computed: {
    ...mapState({
      currentShipping: state => state['shipping-module'].current
    })
  },
  created () {
    void this.getContext()
  },
  methods: {
    ...mapActions({
      restoreOnTabFocus: 'user/restoreOnTabFocus',
      loadDefaultCityAndShipping: 'shipping-module/loadDefaultCityAndShipping'
    }),
    getContext () {
      return updateSiteData(this.$store, this.$ssrContext)
    },
    checkOnActivate () {
      if (document.visibilityState !== 'visible') return

      return allSettled([
        this.restoreOnTabFocus(),
        this.checkShops()
      ])
    },
    checkShops () {
      try {
        if (!this.currentShipping?.shop?.id) {
          return
        }

        return this.$store.dispatch('shipping-module/loadShopList', {
          shopId: this.currentShipping.shop.id,
          notImportant: true,
          checkShop: true
        })
      } catch (e) {}
    },
    fetchMenuData () {
      return this.$store.dispatch('category/list', {
        parent: config.entities.category.categoriesRootCategoryId,
        level:
          config.entities.category.categoriesDynamicPrefetch &&
          config.entities.category.categoriesDynamicPrefetchLevel >= 0
            ? config.entities.category.categoriesDynamicPrefetchLevel
            : null,
        includeFields:
          config.entities.optimize && isServer
            ? config.entities.category.includeFields
            : null,
        excludeFields: [
          ...config.entities.category.excludeFields,
          'description',
          'brand_data'
        ],
        // excludeSlugs: ['brands'],
        includesInMenu: true
      })
    },
    handleTabEvents () {
      addHandler((payload) => {
        this.$store.dispatch('cart/cartUpdateItems', { items: payload?.clientItems })
        this.$store.dispatch('cart/overrideTotalsFromPayload', {
          totals: payload?.totals,
          result: payload?.clientItems
        })
      }, 'cartSyncUpdate')

      addHandler((token) => {
        this.$store.dispatch('cart/setCartToken', { token, emit: false })
      }, 'cartQuoteUpdate')

      addHandler((payload) => {
        this.$store.dispatch('shipping/saveShippingDetailsToStore', { shippingDetails: payload, emit: false })
      }, 'shippingSync')

      addHandler(() => {
        this.$store.dispatch('user/logout', { silent: true, emit: false })
      }, 'userLogout')

      addHandler((waitBeforeSendSms) => {
        this.$bus.$emit('wait-before-send-sms', waitBeforeSendSms)
      }, 'smsSent')
    },
    replaceByRegion ({ region, oldRegion }) {
      if (isServer) return

      try {
        if (region?.id === oldRegion?.id) return

        const route = regionUrl(this.$route, oldRegion, region)

        this.$nextTick(() => {
          this.$router.replace(route).catch(() => 0)
        })
      } catch (e) {}
    }
  }
}

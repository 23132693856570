import { RobotsEnum } from './types';
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import { relRobotsHandler } from 'theme/meta/utils/robots';
import config from 'config';
import { isRegionalPage } from '$modules/shipping/utils/region-url';
import { getCurrentRegion } from 'theme/store/checkout/helpers';

export function metaPages () {
  const title = `${this.$t(this.$route.meta.title)} | VARUS`;
  const description = `${title} ${this.$t('META_DESCRIPTION')}`;
  let relRobots = relRobotsHandler(RobotsEnum.NOINDEXNOFOLLOW, config.xEnv.key);

  if (isRegionalPage(this.$route.fullPath) && !getCurrentRegion()?.is_indexed) {
    relRobots = relRobotsHandler(RobotsEnum.NOINDEXNOFOLLOW, config.xEnv.key);
  }

  const meta = {
    title: title,
    meta: {
      ...relRobots,
      title: {
        name: 'title',
        content: title
      },
      description: {
        name: 'description',
        content: description
      }
    }
  };

  return mergeMeta(meta)
}

import { disableScrollLock, enableScrollLock } from '../../../composables/useScrollLock';
import { generateID } from 'theme/helpers';

export default {
  name: 'ScrollLock',
  data () {
    return {
      id: generateID()
    }
  },
  methods: {
    enableScrollLock () {
      enableScrollLock(this.id)
    },
    disableScrollLock () {
      disableScrollLock(this.id)
    }
  }
};

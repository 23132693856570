import { getProductImagePath } from 'theme/helpers'
import { manifestMeta } from './base'
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import config from 'config';
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import { RobotsEnum } from './types'
import { relRobotsHandler } from 'theme/meta/utils/robots';
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers';

export const SIZE_PRODUCT_GALLERY = {
  desktop: {
    width: 1140,
    height: 1140
  },
  mobile: {
    width: 670,
    height: 670
  }
};

const metaCategories = (categories): any => {
  const result: any = {};

  if (!categories?.length) return result;

  for (const category of categories) {
    result[`category-${category.category_id}`] = {
      property: 'product:category',
      content: category.name
    }
  }

  return result
};

export function metaProduct () {
  const categories = metaCategories(this.getCurrentProduct.category);
  const image = getProductImagePath(this.getCurrentProduct?.sku, SIZE_PRODUCT_GALLERY.mobile.width, SIZE_PRODUCT_GALLERY.mobile.height);
  const i18nStore = config.storeViews?.[this.$store.state.storeView.storeCode]?.i18n
  const currentLocale = i18nStore?.defaultLocale;
  const baseUrl = this.$store.getters['siteData/baseUrl'];
  const url = `${baseUrl}${formatProductLink(this.getCurrentProduct, currentStoreView().storeCode)}`;
  const manifest = manifestMeta(this.$store);
  const relRobots = relRobotsHandler(RobotsEnum.INDEX, config.xEnv.key);

  const meta = {
    title: `${this.getCurrentProduct?.name} ${this.$t('META_ADDITIONAL_TITLE')}`,
    meta: {
      title: { name: 'title', content: `${this.getCurrentProduct?.name} ${this.$t('META_ADDITIONAL_TITLE')}` },
      description: {
        name: 'description',
        content: `${this.getCurrentProduct?.name} ${this.$t('META_DESCRIPTION')}`
      },
      ogTitle: { name: 'og:title', content: `${this.getCurrentProduct?.name} ${this.$t('META_ADDITIONAL_TITLE')}` },
      ogImage: { name: 'og:image', content: image },
      ogDescription: {
        name: 'og:description',
        content: `${this.getCurrentProduct?.name} ${this.$t('META_DESCRIPTION')}`
      },
      ogType: {
        property: 'og:type',
        content: 'product'
      },
      relatedPartNo: {
        property: 'product:retailer_part_no',
        content: this.getCurrentProduct?.sku
      },
      ogLocale: {
        name: 'og:locale',
        content: currentLocale
      },
      ogUrl: {
        name: 'og:url',
        content: url
      },
      ...relRobots,
      ...categories
    },
    link: [
      {
        rel: 'preload',
        href: image,
        as: 'image'
      },
      // temporarily hidden according to task VAR-3141
      // {
      //   rel: 'canonical',
      //   href: url
      // },
      ...manifest.link
    ]
  };

  return mergeMeta(meta)
}
